import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './index.css';

function App() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showClear, setShowClear] = useState(false);
  const [filterColumn, setFilterColumn] = useState('name');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  useEffect(() => {
    // Load and parse the CSV data
    Papa.parse(process.env.PUBLIC_URL + '/venues.csv', {
      download: true,
      header: true,
      complete: (result) => {
        setData(result.data);
      }
    });
  }, []);

  const searchTermCleaned = searchTerm.trim().toLowerCase();
  let filteredData = data.filter(venue => 
    venue[filterColumn] && venue[filterColumn].toString().trim().toLowerCase().includes(searchTermCleaned)
  );

  // Sorting function based on column and direction
  if (sortColumn) {
    filteredData = filteredData.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return -1;
      if (a[sortColumn] > b[sortColumn]) return 1;
      return 0;
    });
    if (sortDirection === 'desc') filteredData.reverse();
  }

  const handleHeaderClick = (column) => {
    if (sortColumn === column) {
      if (!sortDirection) {
        setSortDirection('asc');
      } else if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else {
        setSortDirection(null);
        setSortColumn(null);
      }
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <div className="container max-w-screen-md mx-auto p-4">
      <div className="text-center mb-6">
        <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Your Logo" className="mx-auto w-24" />
      </div>
      <div className="flex flex-col md:flex-row items-center mb-4">
          <div className="relative flex-grow mb-2 md:mb-0">
            <input 
                type="text" 
                placeholder="Search for a venue..." 
                className="p-2 border rounded w-full"
                onChange={e => {
                  setSearchTerm(e.target.value);
                  setShowClear(e.target.value !== '');
                }}
            />
            {showClear && (
              <button 
                onClick={() => {
                  setSearchTerm('');
                  setShowClear(false);
                }}
                className="clear-button text-gray-400 hover:text-gray-500 text-xs font-semibold"
              >
                CLEAR
              </button>
            )}
          </div>
          <select 
              value={filterColumn} 
              onChange={(e) => setFilterColumn(e.target.value)}
              className="ml-0 md:ml-4 p-2 border rounded"
          >
              <option value="name">Venue Name</option>
              <option value="city">City</option>
              <option value="region">Region</option>
              <option value="address">Address</option>
              <option value="capacity">Capacity</option>
          </select>
      </div>
      
      {/* Display for medium and larger screens */}
      <table className="min-w-full mt-4 border-collapse hidden md:table">
          <thead>
              <tr>
                  <th className="p-2 border w-1/5 cursor-pointer" onClick={() => handleHeaderClick('name')}>Venue Name</th>
                  <th className="p-2 border w-1/5 cursor-pointer" onClick={() => handleHeaderClick('city')}>City</th>
                  <th className="p-2 border w-1/5 cursor-pointer" onClick={() => handleHeaderClick('region')}>Region</th>
                  <th className="p-2 border w-1/5 cursor-pointer" onClick={() => handleHeaderClick('address')}>Address</th>
                  <th className="p-2 border w-1/5 cursor-pointer" onClick={() => handleHeaderClick('capacity')}>Capacity</th>
              </tr>
          </thead>
          <tbody>
              {filteredData.map(venue => (
                  <tr key={venue.id}>
                      <td className="p-2 border">{venue.name}</td>
                      <td className="p-2 border">{venue.city}</td>
                      <td className="p-2 border">{venue.region}</td>
                      <td className="p-2 border">{venue.address}</td>
                      <td className="p-2 border">{venue.capacity}</td>
                  </tr>
              ))}
          </tbody>
      </table>
      
      {/* Display for small screens */}
      <div className="md:hidden mt-4">
        {filteredData.map(venue => (
          <div key={venue.id} className="border rounded mb-4 p-2">
            <div><strong>Venue Name:</strong> {venue.name}</div>
            <div><strong>City:</strong> {venue.city}</div>
            <div><strong>Region:</strong> {venue.region}</div>
            <div><strong>Address:</strong> {venue.address}</div>
            <div><strong>Capacity:</strong> {venue.capacity}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
